import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import LogoPath from "../../resources/images/Logo.png";

const PrivacyPolicy = () => {
  const baseUrl = window.location.origin;
  return (
    <Container fluid>
      <Row className="top-left-privacy-logo">
        <a href={`${baseUrl}`} ><Image
          src={LogoPath}
          alt="Logo"
          className=""
        /></a>
      </Row>
      <Row className="privacy-policy" justify="center">
        <Col>
          <p className="para-font">
            <p className="heading-font">Privacy Policy</p>
            <p className="heading-font">Last updated: 17 – 08 - 2023</p>
            We respect your privacy and are committed to protecting it through
            our compliance with this privacy policy. This Policy describes the
            types of information we may collect from you or that you may provide
            on consultarer.com, the Consultarer application, and/or any of its
            related products and services, and our practices for collecting,
            using, maintaining, protecting, and disclosing the collected
            Personal Information. It also describes the choices available to you
            regarding our use of your Personal Information and how you can
            access and update it. By entering your email, you acknowledge that
            you have read, understood and agree to be bound by this policy. We
            are committed to protecting your privacy and ensuring the security
            of your personal information. . <br /> <br /><br/>
            <span className="heading-font">Information We Collect </span><br/> <br />
            We may collect the following types of information from you:
            <br /> <br />
            <span className="heading-font">1. Personal Information:</span>
            This includes email address, and any other information you
            voluntarily provide when signing up for our beta tester waitlist.{" "}
            <br /> <br />
            <span className="heading-font">2. Usage Information:</span> We may
            collect information about how you interact with our website,
            including your IP address, browser type, and pages visited.
            <br /><br/>
            <br />
            <span className="heading-font"> Use of Your Information</span>{" "}<br/>
            <br /> We use the information we collect for various purposes,
            including but not limited to: <br /> <br />{" "}
            <span className="heading-font">- To Manage the Wait list:</span> We
            use your email address to send you updates about the beta testing
            process and to notify you when our services are ready for testing.{" "}
            <br />
            <br />
            <span className="heading-font">- To Improve Our Services:</span> We
            may analyze usage patterns to enhance our website and services based
            on user preferences and needs. <br /> <br /><br/>
            <span className="heading-font">GDPR Compliance</span> <br/><br/>We are
            committed to complying with the General Data Protection Regulation
            (GDPR) and similar data protection laws. The regulation defines
            personal data as “any information relating to an identified or
            identifiable natural person,” who is called a data subject. Personal
            data includes IP addresses, email addresses and telephone numbers.
            Processing refers to any operation performed on personal data,
            including its collection, use, storage and disclosure. Controllers
            are organizations responsible for determining the purposes and means
            of processing personal data—why and how they intend to collect and
            use the personal data. Organizations that process personal data on
            behalf of a controller are called processors If you are located in
            the European Economic Area (EEA) or another region with similar
            laws, the following applies: <br /> <br />
            <span className="heading-font">
              - Legal Basis for Processing:
            </span>{" "}
            We will only process your personal information if we have a lawful
            basis for doing so, such as your consent or our legitimate
            interests. <br /> <br />
            <span className="heading-font">- Data Subject Rights:</span> You
            have the right to access, rectify, or erase your personal
            information, and to restrict or object to its processing. You can
            exercise these rights by contacting us at {" "}
            <span className="privacy-policy-text">
              <a href="mailto:privacy-policy@consultarer.com">
                  privacy-policy@consultarer.com
              </a>
            </span> <br /> <br />
            <span className="heading-font">
              - International Data Transfers:
            </span>{" "}
            Your data may be transferred and processed outside of your country
            of residence, including to countries with different data protection
            laws. We have contracted a GDPR compliant processor for the
            collection and storage of these personal information. By using our
            services, you consent to such transfers. <br /> <br /><br/>
            <span className="heading-font">
              Third-Party Services
            </span> <br/><br /> We may use third-party services that collect,
            monitor, and analyze user data to improve our services. <br />{" "}
            <br />
            <br/><span className="heading-font">
              Data and Information security
            </span>{" "}<br/>
            <br /> We strive daily to ensure that we secure the information you
            provide on trusted and reputable cloud server provider and
            affiliate, and we ensure that we take internal proactive steps to
            make sure, these data are accessed in a controlled, secure
            environment, protected from unauthorized access, use, or disclosure.
            We maintain reasonable administrative, technical, and physical
            safeguards in an effort to protect against unauthorized access, use,
            modification, and disclosure of Personal Information submitted to
            our platform. However, no data transmission over the Internet or
            wireless network can be guaranteed. Therefore, while we strive to
            protect your Personal Information, you acknowledge that; <br />
            <br />
            (i) There are security and privacy limitations of the Internet which
            are beyond our control;
            <br /> <br />
            (ii) The security, integrity, and privacy of any and all information
            and data exchanged between you and our platform cannot be
            guaranteed;
            <br /> <br />
            (iii) Any such information and data may be viewed or tampered with
            in transit by a third party, despite best efforts, as the security
            of Personal Information depends in part on the security of the
            device you use to communicate with us and the security you use to
            protect your credentials, please take appropriate measures to
            protect this information.
            <br />
            <br /><br/>
            <span className="heading-font"> Data breach</span> <br /> <br/>In the
            event we become aware that the security of the platform has been
            compromised or beta Users’ Personal Information has been disclosed
            to unrelated third parties as a result of external activity,
            including, but not limited to, security attacks or fraud, we reserve
            the right to take reasonably appropriate measures, including, but
            not limited to, investigation and reporting, as well as notification
            to and cooperation with law enforcement authorities. In the event of
            a data breach, we will make reasonable efforts to notify affected
            individuals if we believe that there is a reasonable risk of harm to
            beta users as a result of the breach, or if notice is otherwise
            required by law. When we do, we will post a notice via email and
            other types of notification methods. <br /> <br /><br/>
            <span className="heading-font">Changes and amendments</span> <br /><br/>
            We reserve the right to modify this Policy, or its terms related to
            the platform at any time at our discretion. When we do, we will
            revise the updated date at the top of this page. We may also provide
            notice to you in other ways at our discretion, such as through the
            contact information you have provided. An updated version of this
            Policy will be effective immediately upon the posting of the revised
            Policy unless otherwise specified. <br /> <br /><br/>
            <span className="heading-font">Contact Us </span> <br /><br/>
            If you have any questions about this Privacy Policy or our data
            practices, please contact us at <span className="privacy-policy-text">
              <a href="mailto:privacy-policy@consultarer.com">
                privacy-policy@consultarer.com
              </a></span>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
